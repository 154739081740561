import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { css } from '@emotion/css';

const blockStyles = css`
    min-height: 200px;
    padding: 24px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
`;

const Marketing = ({ title, text }) => (

        <Card className={blockStyles}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {text}
                </Card.Text>
            </Card.Body>
        </Card>

);

export default Marketing;
