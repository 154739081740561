import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth'
import clusterReducer from './clusters'
import contentReducer from './content'
import subTopicReducer from "./subTopics";
import knowledgeReducer from "./knowledge";
import courseReducer from "./courses";
const store = configureStore({
    reducer: {
        auth : authReducer,
        clusters : clusterReducer,
        content : contentReducer,
        subtopics: subTopicReducer,
        knowledge: knowledgeReducer,
        courses: courseReducer,
    }
});

export default store;
