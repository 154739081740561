// knowledgeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';

// Initial state
const initialState = {
    knowledge: [],
    loading: false,
    error: null,
};

// Async thunk for fetching knowledge collection
export const fetchKnowledge = createAsyncThunk(
    'knowledge/fetchKnowledge',
    async (_, { rejectWithValue }) => {
        try {
            const knowledgeRef = ref(database, 'knowledge'); // Ensure 'knowledge' is the correct path
            const snapshot = await get(knowledgeRef);
            if (snapshot.exists()) {
                const knowledge = snapshot.val();
                return Object.values(knowledge); // Convert to array if it's an object
            } else {
                throw new Error("Knowledge collection not found");
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Create slice
const knowledgeSlice = createSlice({
    name: 'knowledge',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKnowledge.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchKnowledge.fulfilled, (state, action) => {
                state.loading = false;
                state.knowledge = action.payload;
            })
            .addCase(fetchKnowledge.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Selectors
export const selectKnowledge = (state) => state.knowledge.knowledge;
export const selectKnowledgeLoading = (state) => state.knowledge.loading;
export const selectKnowledgeError = (state) => state.knowledge.error;

export default knowledgeSlice.reducer;
