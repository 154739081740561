import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import WebTemplate from '../components/WebTemplate';
import { css } from '@emotion/css';
import hero from '../assets/images/ai-features.jpg';
import { FaBook, FaUserTie, FaBrain, FaSearch, FaChartLine, FaCertificate } from 'react-icons/fa';

const imagePlaceholder = css`
    height: 420px;
    background: #ccc;
    border-radius: 5px;
    margin: 24px 0;
    background-image: url(${hero});
    background-size: cover;
    background-position: center;
`;

const customStyles = css`
    h1 {
        margin-top: 24px;
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
    }
    p {
        line-height: 1.6;
    }
    .row {
        margin-top: 16px;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        text-align: left;
    }
    .card-icon {
        font-size: 3rem;
        margin-bottom: 16px;
        color: #004080
    }
    .card-title {
        font-size: 1.25rem;
        font-weight: 500;
        color: #333;
    }
`;

const features = [
    {
        icon: <FaBook className="card-icon" />,
        title: 'Course Catalog',
        description: 'Explore a comprehensive catalog of courses across various subjects and levels, designed to meet the diverse learning needs of students and professionals.'
    },
    {
        icon: <FaUserTie className="card-icon" />,
        title: 'Author Expertise',
        description: 'Learn from industry experts and renowned authors who bring their real-world experience and knowledge to each course.'
    },
    {
        icon: <FaBrain className="card-icon" />,
        title: 'Adaptive Learning',
        description: 'Benefit from personalized learning experiences that adapt to your individual progress, ensuring you achieve mastery in every topic.'
    },
    {
        icon: <FaSearch className="card-icon" />,
        title: 'Intelligent Search',
        description: 'Utilize our advanced search functionality to quickly find the courses, resources, and information you need to succeed.'
    },
    {
        icon: <FaChartLine className="card-icon" />,
        title: 'Analytics',
        description: 'Track your learning progress with detailed analytics and insights that help you stay on top of your educational goals.'
    },
    {
        icon: <FaCertificate className="card-icon" />,
        title: 'Credentials',
        description: 'Earn recognized credentials and certificates upon course completion, boosting your resume and professional profile.'
    },
];

const Features = function() {
    return (
        <WebTemplate>
            <Container className={customStyles}>
                <Row>
                    <Col>
                        <h1>Features of Pearson Advantage</h1>
                        <div className={imagePlaceholder}></div>
                    </Col>
                </Row>
                <Row>
                    {features.map((feature, index) => (
                        <Col key={index} md={4} style={{marginBottom: '20px'}}>
                            <Card>
                                <Card.Body>
                                    {feature.icon}
                                    <Card.Title>{feature.title}</Card.Title>
                                    <Card.Text>{feature.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </WebTemplate>
    );
}

export default Features;
