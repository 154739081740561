import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WebTemplate from '../components/WebTemplate';
import { Container } from 'react-bootstrap';
import { css } from '@emotion/css';
import { FaPlay } from 'react-icons/fa'; // Importing the FaPlay icon from react-icons/fa
import hero from '../assets/images/ai-adaptive-learning.jpg';

const imagePlaceholder = css`
    height: 420px;
    position: relative;
    border-radius: 8px;
    margin: 24px 0;
    overflow: hidden;
    background: url(${hero}) no-repeat center center;
    background-size: cover;
`;

const overlayStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const playButtonStyles = css`
    color: #fff; /* Adjust icon color */
    font-size: 3rem; /* Adjust icon size */
    cursor: pointer;
`;

const customStyles = css`
    h1 {
        margin-top: 24px;
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
    }
    p {
        line-height: 1.6;
    }
    .row {
        margin-top: 16px;
    }
    .col {
        margin-bottom: 24px;
    }
`;

const AboutAdaptive = function() {
    return (
        <WebTemplate>
            <Container className={customStyles}>
                <Row>
                    <Col>
                        <h1>About Adaptive Learning</h1>
                        <div className={imagePlaceholder}>
                            <div className={overlayStyles}>
                                <FaPlay className={playButtonStyles} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <p>Adaptive learning is an educational method which uses technology to tailor teaching to the needs of individual students. By analyzing data on student performance, adaptive learning systems can adjust the material presented, pacing, and difficulty to better suit each learner’s unique strengths and weaknesses. This approach aims to enhance the educational experience by providing a more personalized learning path.</p>
                        <p>With adaptive learning, students receive immediate feedback and customized recommendations, helping them to stay engaged and motivated. This method not only supports individual learning styles but also helps in identifying areas where a student may need additional support. The ultimate goal is to improve overall educational outcomes by making learning more effective and enjoyable.</p>
                        <p>At the core of adaptive learning is sophisticated software that leverages artificial intelligence and machine learning algorithms. These systems continuously gather and analyze data as students interact with the material, allowing for real-time adjustments to their learning paths. This dynamic and responsive approach ensures that students are always challenged at an appropriate level, preventing frustration and boredom.</p>
                        <p>Adaptive learning technologies are increasingly being integrated into classrooms and online education platforms. They offer a scalable solution to personalized education, making it feasible to cater to the needs of each student in a diverse classroom setting. By bridging gaps in knowledge and reinforcing concepts as needed, adaptive learning empowers students to achieve their full potential.</p>
                    </Col>
                    <Col md={6}>
                        <p>Another significant advantage of adaptive learning is its ability to provide detailed analytics and insights to educators. These insights can highlight trends and pinpoint areas where students commonly struggle, enabling teachers to intervene more effectively. Educators can use this data to adjust their instructional strategies and provide targeted support, making the learning process more efficient.</p>
                        <p>The impact of adaptive learning is profound, as it transforms the traditional one-size-fits-all approach to education into a customized learning journey. This approach not only benefits students but also aids educators in delivering high-quality education tailored to the needs of their students. As technology continues to evolve, the capabilities of adaptive learning systems are expected to expand, further revolutionizing the education landscape.</p>
                        <p>In conclusion, adaptive learning represents a significant shift in educational methodologies, leveraging technology to provide a more personalized and effective learning experience. By continuously adapting to the needs of individual students, this approach ensures that every learner has the opportunity to succeed. As more educational institutions adopt adaptive learning technologies, we can expect to see improvements in student engagement, achievement, and satisfaction.</p>
                        <p>At our institution, we are committed to integrating adaptive learning into our curriculum to support our students’ diverse learning needs. We believe that this innovative approach will not only enhance the educational experience but also prepare our students for future success in an ever-changing world.</p>
                    </Col>
                </Row>
            </Container>
        </WebTemplate>
    );
}

export default AboutAdaptive;
