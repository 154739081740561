import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';

// Initial state
const initialState = {
    clusters: [],
    loading: false,
    error: null,
};

// Async thunk for fetching clusters (categories)
export const fetchClusters = createAsyncThunk(
    'clusters/fetchClusters',
    async (_, { rejectWithValue }) => {
        try {
            const clusterRef = ref(database, 'category'); // Ensure 'category' is the correct path
            const snapshot = await get(clusterRef);
            if (snapshot.exists()) {
                const clusters = snapshot.val();
                return Object.values(clusters); // Convert to array if it's an object
            } else {
                throw new Error("Clusters not found");
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Create slice
const clusterSlice = createSlice({
    name: 'clusters',
    initialState,
    reducers: {
        setCluster: (state, action) => {
            // Assuming action.payload contains the new clusters data
            state.clusters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClusters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchClusters.fulfilled, (state, action) => {
                state.loading = false;
                state.clusters = action.payload;
            })
            .addCase(fetchClusters.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions from the slices for use in components.
export const { setCluster } = clusterSlice.actions;
export default clusterSlice.reducer;
