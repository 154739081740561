import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ObrizumTemplate from '../../components/ObrizumTemplate';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Home = function(){
    const auth = useSelector(state => state.auth)
    
    return (
        <ObrizumTemplate>
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Obrizum home</h1>
                        <ul>
                            { auth.me.courses ?
                            auth.me.courses.map((course, i) => (
                                <li><Link to={process.env.PUBLIC_URL+"/obrizum/course/"+course.shortcode}>{course.name}</Link></li>
                            )) :
                            <></>
                            }
                        </ul>
                    </Col>
                </Row>
            </Container>
        </ObrizumTemplate>
    )
}

export default Home