import React from 'react';
import { css } from '@emotion/css';
import hero from '../assets/images/hero.jpg'
const heroStyles = css`
    background: #efefef;
    height: 420px;
    padding: 32px;
    position: relative;
    margin-bottom:24px;
    background-image: url(${hero});
    background-size: 110% no-repeat;
    background-position: -10px -400px;
    
    h1 {
        position: relative;
        bottom: -258px;
        background: #00408030;
        backdrop-filter: blur(30px);
        padding:15px;
        border-radius:15px;
        color: white;
        font-size: 2.3em;
        font-weight: bold;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        max-width: 1024px;
        
        margin: 0 auto;
    }
`;

const Hero = () => (
    <div className={heroStyles}>
        <h1 style={{top: '100px'}}>Welcome to Pearson Advantage</h1>
    </div>
);

export default Hero;
