import React from 'react';
import { css } from '@emotion/css';

const footerStyles = css`
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 15px;
    border-top: 1px solid #ccc;
    margin-top: 24px;
    text-align: center;
    background-color: #efefef;
    width: 100%;
    position: fixed;
    bottom: 0;
`;

const Footer = () => (
    <footer className={footerStyles}>

        Copyright Pearson 2024
    </footer>
);

export default Footer;
