import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ObrizumTemplate from '../../components/ObrizumTemplate';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ObrizumCourse from '../../components/ObrizumCourse';
import { css } from '@emotion/css';
import Breadcrumbs from '../../components/Breadcrumbs';

const Course = function() {

    const courses = useSelector(state => state.courses.courses || []);
    const { id } = useParams();

    console.log('11', courses);
    console.log('Type of clusters:', typeof courses);
    console.log('18', id);

    const getCourse = function(id) {
        // Find the course with courses.ISBN === id
        return courses.find(course => course?.ISBN === parseInt(id));
    };

    const course = getCourse(id);

    const courseHeaderStyles = css`
  
        margin: 0 -12px 12px;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
    `;
    const courseContainerStyles = css`
        margin: 0 auto;
        max-width: 1440px;


    `;
    const courseContainer = css`
        margin: 0 auto;
        background: #ccc;
        width:100%;
    `;
    return (
        <ObrizumTemplate>
            {course ? (
                <>
                <div className={courseContainer}>
                    <div className={courseContainerStyles}>
                        <h1 className={courseHeaderStyles}>{course.Title}</h1>
                    </div>

                </div>
                    <Breadcrumbs course={course}></Breadcrumbs>
                <ObrizumCourse data={course} title={course.Description}></ObrizumCourse>
                </>
            ) : (
                <p>Course not found</p>
            )}
        </ObrizumTemplate>
    );
};

export default Course;
