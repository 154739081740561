import React, { useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Chart, ArcElement, Tooltip, Legend, PieController } from 'chart.js';
import { css } from '@emotion/css';
import ObrizumTemplate from '../../components/ObrizumTemplate';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Register the necessary chart.js components
Chart.register(ArcElement, Tooltip, Legend, PieController);

const sidebarStyle = css`
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 1.5rem;
`;

const listGroupItemStyle = css`
    padding: 1rem;
    border: none;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: flex-start;
    transition: background-color 0.2s;
    flex-direction: row !important;
    justify-content: flex-start;

    &:hover {
        background-color: #e9ecef;
    }

    &.active {
        background-color: #007bff;
        color: white;
    }

    &.text-danger {
        color: #dc3545 !important;
    }
`;

const mainContentStyle = css`
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const Analytics = function () {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const auth = useSelector((state) => state.auth);
    const pieData = {
        labels: ['Correct', 'Incorrect'],
        datasets: [
            {
                data: [33, 67],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const chart = new Chart(chartRef.current, {
            type: 'pie',
            data: pieData,
            options: {
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
            },
        });

        chartInstanceRef.current = chart;

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [pieData]);

    const defaultDateTo = moment().format('YYYY-MM-DD');
    const defaultDateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');

    return (
        <ObrizumTemplate>
            <Row className="my-4">
                <Col xs={12} md={3}>
                    <div className={sidebarStyle}>
                        <h5>Knowledge Spaces</h5>
                        <Form>
                            <Form.Group controlId="formKnowledgeSpaces" className="mb-3">
                                <Form.Control as="select">
                                    {auth.me.courses ? auth.me.courses.map((course, i) => (
                                        <option key={i}>{course.Title}</option>
                                    )) : null}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formDateFrom" className="mb-3">
                                <Form.Label>Date from</Form.Label>
                                <Form.Control type="date" defaultValue={defaultDateFrom} />
                            </Form.Group>
                            <Form.Group controlId="formDateTo" className="mb-3">
                                <Form.Label>Date to</Form.Label>
                                <Form.Control type="date" defaultValue={defaultDateTo} />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mb-2 w-100 pricing-button">
                                Apply
                            </Button>
                            <Button variant="secondary" type="button" className="w-100 pricing-button">
                                Refresh data
                            </Button>
                        </Form>
                    </div>
                </Col>
                <Col xs={12} md={9}>
                    <div className={mainContentStyle}>
                        <h5 className="text-center">Your score</h5>
                        <h3 className="text-center mb-4">150</h3>
                        <Form.Group controlId="formConcepts" className="mb-3">
                            <Form.Control as="select">
                                <option>All concepts</option>
                                {/* Add more options as needed */}
                            </Form.Control>
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                            <div style={{ width: '50%', maxWidth: '300px' }}>
                                <canvas ref={chartRef}></canvas>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </ObrizumTemplate>
    );
};

export default Analytics;
