import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Breadcrumbs = (props) => {
    const breadcrumbStyles = css`
        margin: 0 auto;
        max-width: 1440px;
        font-size: 14px;
    `;

    return (
        <div className={breadcrumbStyles}>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: process.env.PUBLIC_URL + '/' }}>
                    Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Classroom</Breadcrumb.Item>

            </Breadcrumb>
        </div>
    );
}

export default Breadcrumbs;
