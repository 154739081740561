import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WebTemplate from '../components/WebTemplate';
import { Button, Container, Card } from 'react-bootstrap';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router';
import { FaRegSmile, FaRegGrinStars, FaRegLaughBeam } from 'react-icons/fa';
import bg from '../assets/images/bg.jpg';
const customStyles = css`
    h1 {
        margin-top: 24px;
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
    }
    .pricing-row {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .row {
        margin-top: 16px;
    }
    .pricing-card {
        padding: 20px;
        margin: 15px;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s;
        background: #fff;
    }
    .pricing-card:hover {
        transform: translateY(-10px);
    }
    .pricing-amount {
        font-size: 24px;
        font-weight: bold;
        margin-top: 10px;
        color: #004080
    }
    .pricing-button {
        margin-top: 16px;
        background: transparent;
        border: 2px solid #004080;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #004080;
        transition: background 0.3s, color 0.3s;
    }
    .pricing-button:hover {
        background: #007bff;
        color: #fff;
    }
    .custom-bg {
        background-image: url(${bg});; /* Add your background image URL */
        background-size: cover;
        background-position: center;
        padding: 50px 0;
    }
    
    .footer-text {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin: 20px 0;
        color: #fff;
    }
    .trial-button {
        margin-top: 20px;
        background: linear-gradient(to right, #007bff, #0056b3);
        border: none;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        transition: background 0.3s;
    }
    .trial-button:hover {
        background: linear-gradient(to right, #0056b3, #003580);
    }
    .icon {
        font-size: 50px;
        color: #004080;
        margin-bottom: 10px;
        align-self: center;
    }
`;

const Pricing = function() {
    const navigate = useNavigate();
    const startTrial = () => {
        navigate(process.env.PUBLIC_URL + "/subscription");
    };

    return (
        <WebTemplate>
            <div className="custom-bg">
                <Container className={customStyles}>
                    <Row>
                        <Col>
                            <h1>Subscription Pricing</h1>
                        </Col>
                    </Row>

                    <Row className="pricing-row">
                        <Col md={4}>
                            <Card className="pricing-card">
                                <Card.Body>
                                    <FaRegSmile className="icon" />
                                    <Card.Title>Monthly</Card.Title>
                                    <Card.Text className="pricing-amount">$39.99 per month</Card.Text>
                                    <Card.Text>Patrons who contribute at this level get my thanks and access to recipes and flash fiction.</Card.Text>
                                    <Button className="pricing-button" onClick={startTrial}>Join</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="pricing-card">
                                <Card.Body>
                                    <FaRegLaughBeam className="icon" />
                                    <Card.Title>Annually</Card.Title>
                                    <Card.Text className="pricing-amount">$359.99 per year</Card.Text>
                                    <Card.Text>You get all the goodies, my thanks, written content, and you'll see concept art for Jazzland before it goes public.</Card.Text>
                                    <Button className="pricing-button" onClick={startTrial}>Join</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="footer-text">
                            <p>Aliquam in ultricies dui, eu efficitur tellus. </p>
                            <Button className="pricing-button" onClick={startTrial}>Start your free trial now!</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </WebTemplate>
    );
};

export default Pricing;
