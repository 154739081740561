import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, Card } from 'react-bootstrap';
import { css } from '@emotion/css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import WebTemplate from '../components/WebTemplate';
import hero from '../assets/images/catalog.jpg';
import { fetchSubtopics, selectSubtopics } from '../redux/subTopics'; // Import the fetchSubtopics thunk and selector


const heroStyles = css`
    background-image: url(${hero});
    height: 380px;
    padding: 32px 0;
    position: relative;
    padding-top: 260px;
    margin-bottom: 24px;
    background-size: cover;
    background-position: center;

    form {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1024px;
        margin: 0 auto;
    }

    input {
        width: 100%;
        display: block;
        height: 75px;
        margin-right: 20px;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    }

    h1 {
        position: relative;
        background: #00408030;
        backdrop-filter: blur(30px);
        padding: 15px;
        border-radius: 15px;
        color: white;
        font-size: 2.3em;
        font-weight: bold;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        max-width: 1024px;
        margin: 0 auto;
        top: -100px;
    }

    .search-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        backdrop-filter: blur(5px);
        width: 100%;
    }

    .search-form {
        display: flex;
        width: 100%;
        max-width: 1024px;
        position: relative;
    }

    .search-form button {
        background: transparent;
        border: 0px;
        font-size: 16px;
        font-weight: bold;
        color: #004080;
        transition: background 0.3s, color 0.3s;
        position: absolute;
        right: 30px;
    }
`;

const contentStyles = css`
    .content-header {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: left;
        font-size: 1.8em;
        font-weight: bold;
        padding-left: 15px;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1440px;
        margin: 0 auto;
        padding-top: 100px;
    }

    .card {
        width: 100%;
        max-width: 320px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s, box-shadow 0.3s;
        margin-bottom: 20px;

        &:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        .card-body {
            padding: 20px;
        }

        .card-title {
            font-size: 1.5em;
            margin-bottom: 15px;
        }

        .card-text {
            font-size: 1em;
            color: #666;
        }

        .subtopics {
            margin-top: 15px;
            list-style: none;
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            li {
                margin-bottom: 5px;

                a {
                    color: #241d1d;
                    background-color: #edf0f7; /* Softer color */
                    border-radius: 20px;
                    padding: 5px 15px;
                    font-size: 0.75em; /* Smaller font size */
                    text-decoration: none;
                    transition: background-color 0.3s, transform 0.3s;

                    &:hover {
                        background-color: #5a6268;
                        transform: scale(1.1);
                        color: white;
                    }
                }
            }
        }
    }
`;

const Catalog = () => {
    const dispatch = useDispatch();
    const { clusters } = useSelector(state => state.clusters);
    const subtopics = useSelector(selectSubtopics);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        dispatch(fetchSubtopics()); // Fetch subtopics on mount
    }, [dispatch]);

    const submitSearch = (e) => {
        e.preventDefault();
        navigate(process.env.PUBLIC_URL + "/search/?q=" + searchQuery);
    };

    const changeSearch = (e) => {
        setSearchQuery(e.target?.value);
    };

    return (
        <WebTemplate>
            <Container fluid>
                <Row>
                    <Col>
                        <div className={heroStyles}>
                            <h1>Find your next thing</h1>
                            <div className="search-container">
                                <Form onSubmit={submitSearch} className="search-form">
                                    <Form.Control type="search" value={searchQuery} onChange={changeSearch} placeholder="search our catalog" />
                                    <Button type="submit">Search</Button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={contentStyles}>
                            <div className="card-container">
                                {Array.isArray(clusters) && clusters.length > 0 ? (
                                    clusters.map((cluster, i) => (
                                        <Card key={i}>
                                            <Card.Body>
                                                <Card.Title>{cluster.category}</Card.Title>
                                                <Card.Text>{cluster.description}</Card.Text>
                                                <p><Link to={process.env.PUBLIC_URL + "/cluster/" + cluster.id}>view more detail</Link></p>
                                                <p><strong>Subtopics:</strong></p>
                                                <ul className="subtopics">
                                                    {/* Render subtopics */}
                                                    {subtopics.filter(subtopic => subtopic.catId === cluster.id).map((subtopic, ii) => (
                                                        <li key={ii}>
                                                            <Link to={process.env.PUBLIC_URL + "/subtopic/" + subtopic.id}>{subtopic.name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    ))
                                ) : (
                                    <p>Loading clusters...</p>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </WebTemplate>
    );
};

export default Catalog;
