// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD8edDXmvWJlXW0Liadhvxkb82rjmXoe9U",
    authDomain: "pearson-advantage.firebaseapp.com",
    projectId: "pearson-advantage",
    storageBucket: "pearson-advantage.appspot.com",
    messagingSenderId: "144385890534",
    appId: "1:144385890534:web:49f341ffe84f38b2ab4327",
    measurementId: "G-BNRX0F00WX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Set the persistence to local
setPersistence(auth, browserLocalPersistence)
    .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
    })
    .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
    });

// Initialize Firebase Realtime Database and get a reference to the service
const database = getDatabase(app);

export { auth, database };
