import { createSlice } from '@reduxjs/toolkit';
import contentData from './data/content.json'

// Modal slice to manage the visibility of different screens within the application.
const contentSlice = createSlice({
    name: 'content',
    initialState: contentData,
    reducers: {
        setContent : (state, action) => {
            // not sure
        },
    },
});

// Export actions from the slices for use in components.
export const { setContent } = contentSlice.actions;
export default contentSlice.reducer;