import { createSlice } from '@reduxjs/toolkit';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { ref, get, update } from 'firebase/database';
import { auth, database } from '../firebase'; // Ensure correct import

// Initial state
const initialState = {
    loggedIn: false,
    me: {},
    error: null,
};

// Create slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.loggedIn = true;
            state.me = action.payload;
            state.error = null;
        },
        loginFailure: (state, action) => {
            state.loggedIn = false;
            state.me = {};
            state.error = action.payload;
        },
        logoutSuccess: (state) => {
            state.loggedIn = false;
            state.me = {};
            state.error = null;
        },
        logoutFailure: (state, action) => {
            state.error = action.payload;
        },
        updateSavedCourses: (state, action) => {
            if (state.me) {
                state.me.courses = action.payload;
            }
        },
    },
});

// Export actions
export const { loginSuccess, loginFailure, logoutSuccess, logoutFailure, updateSavedCourses } = authSlice.actions;

// Async thunk for login
export const login = (email, password) => async (dispatch) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Fetch user profile from Realtime Database
        const userRef = ref(database, 'users/' + user.uid);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
            const userData = snapshot.val();
            dispatch(loginSuccess(userData));
        } else {
            throw new Error("User data not found");
        }
    } catch (error) {
        dispatch(loginFailure(error.message));
    }
};

// Async thunk for logout
export const logout = () => async (dispatch) => {
    try {
        await signOut(auth);
        dispatch(logoutSuccess());
    } catch (error) {
        dispatch(logoutFailure(error.message));
    }
};

// Async thunk for removing a course
export const removeCourse = (courseISBN) => async (dispatch, getState) => {
    const state = getState();
    const { loggedIn, me } = state.auth;

    if (loggedIn && me && me.courses) {
        const updatedCourses = me.courses.filter(course => course.ISBN !== courseISBN);

        console.log('85', me)
        // Update courses in Realtime Database
        const userRef = ref(database, 'users/' + me.id);
        try {
            await update(userRef, { courses: updatedCourses });
            dispatch(updateSavedCourses(updatedCourses));
            console.log('Updated courses in the database:', updatedCourses); // Logging the update
        } catch (error) {
            console.error("Error updating courses: ", error);
        }
    }
};

export default authSlice.reducer;
