import React, { useState, useMemo, useCallback } from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { css } from '@emotion/css';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Search = () => {
    const courses = useSelector(state => state.courses.courses);
    const subtopics = useSelector(state => state.subtopics.subtopics);
    const clusters = useSelector(state => state.clusters.clusters);
    const auth = useSelector(state => state.auth);
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();

    const styles = css`
        .rbt-menu {
            width: 500px;
            right: 0;
            position: absolute;
        }

        .rbt-menu .rbt-menu-item {
            margin: 5px 10px;
            padding: 10px;
            border-radius: 4px;
            transition: background-color 0.3s ease;
        }

        .rbt-menu .rbt-menu-item:hover {
            background-color: #f0f0f0;
        }

        .rbt-menu-header {
            padding: 10px 20px;
            font-weight: bold;
            background-color: #e9ecef;
            border-bottom: 1px solid #dee2e6;
        }
    `;

    const filterBy = useCallback((option, state) => {
        const searchText = state.text.toLowerCase();
        return (
            (option.Title && option.Title.toLowerCase().includes(searchText)) ||
            (option.name && option.name.toLowerCase().includes(searchText)) ||
            (option.category && option.category.toLowerCase().includes(searchText))
        );
    }, []);

    const handleSelection = (selected) => {
        setSelected(selected);
        if (!auth.me) {
            navigate(`${process.env.PUBLIC_URL}/subscription`);
        } else if (selected.length > 0) {
            const selectedItem = selected[0];
            if (selectedItem.category) {
                navigate(`${process.env.PUBLIC_URL}/cluster/${selectedItem.id}`);
            } else if (selectedItem.name && selectedItem.catId) {
                navigate(`${process.env.PUBLIC_URL}/cluster/${selectedItem.catId}/${selectedItem.id}`);
            } else if (selectedItem.Title && selectedItem.ISBN) {
                navigate(`${process.env.PUBLIC_URL}/obrizum/course/${selectedItem.ISBN}`);
            }
        }
    };

    const renderMenu = useCallback((results, menuProps) => {
        const categoryResults = results.filter(result => result.category);
        const subtopicResults = results.filter(result => result.name);
        const courseResults = results.filter(result => result.Title);

        return (
            <Menu {...menuProps}>
                {categoryResults.length > 0 && (
                    <>
                        <div className="rbt-menu-header">
                            Categories
                        </div>
                        {categoryResults.map((option, index) => (
                            <MenuItem key={`category-${index}`} option={option} position={index}>
                                <div>
                                    <strong>{option.category}</strong>
                                    <div>
                                        <small>{option.description}</small>
                                    </div>
                                </div>
                            </MenuItem>
                        ))}
                    </>
                )}
                {subtopicResults.length > 0 && (
                    <>
                        <div className="rbt-menu-header">
                            Subtopics
                        </div>
                        {subtopicResults.map((option, index) => (
                            <MenuItem key={`subtopic-${index}`} option={option} position={index}>
                                <div>
                                    <strong>{option.name}</strong>
                                    <div>
                                        <small>{option.description}</small>
                                    </div>
                                </div>
                            </MenuItem>
                        ))}
                    </>
                )}
                {courseResults.length > 0 && (
                    <>
                        <div className="rbt-menu-header">
                            Courses
                        </div>
                        {courseResults.map((option, index) => (
                            <MenuItem key={`course-${index}`} option={option} position={index}>
                                <div>
                                    <strong>{option.Title}</strong>
                                    <div>
                                        <small>Author: {option.Author}</small>
                                    </div>
                                    <div>
                                        <small>Format: {option.Format}</small>
                                    </div>
                                </div>
                            </MenuItem>
                        ))}
                    </>
                )}
            </Menu>
        );
    }, []);

    const options = useMemo(() => [...courses, ...subtopics, ...clusters], [courses, subtopics, clusters]);

    return (
        <div className={styles}>
            <Typeahead
                id="search"
                labelKey={option => option.Title || option.name || option.category}
                onChange={handleSelection}
                options={options}
                placeholder="Search for a course, subtopic, or category..."
                selected={selected}
                filterBy={filterBy}
                renderMenu={renderMenu}
                align="right"
            />
        </div>
    );
};

export default Search;
