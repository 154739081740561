// courseSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';

// Initial state
const initialState = {
    courses: [],
    loading: false,
    error: null,
};

// Async thunk for fetching courses collection
export const fetchCourses = createAsyncThunk(
    'courses/fetchCourses',
    async (_, { rejectWithValue }) => {
        try {
            const coursesRef = ref(database, 'course'); // Ensure 'courses' is the correct path
            const snapshot = await get(coursesRef);
            if (snapshot.exists()) {
                const courses = snapshot.val();
                return Object.values(courses); // Convert to array if it's an object
            } else {
                throw new Error("Courses collection not found");
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Create slice
const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload;
            })
            .addCase(fetchCourses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Selectors
export const selectCourses = (state) => state.courses.courses;
export const selectCoursesLoading = (state) => state.courses.loading;
export const selectCoursesError = (state) => state.courses.error;

export default courseSlice.reducer;
