import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WebTemplate from '../components/WebTemplate';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { css } from '@emotion/css';

const listNone = css`
    list-style: none;
    margin: 0;
    padding: 0;
`
const boxStyle = css`
    border: 1px solid #ccc;
    padding: 24px;
    border-radius: 5px;
    margin: 12px 0;
`

const SearchResults = function(){
    const [searchParams, setSearchParams] = useSearchParams()
    const clusters = useSelector(state => state.clusters)

    return (
        <WebTemplate>
            <h1>Search Results: {searchParams.get("q")}</h1>
            <Container>
                <Row>
                    <Col sm={2}>
                        <p>Filters:</p>
                        <ul>
                            <li>Filter 1</li>
                            <li>Filter 2</li>
                            <li>Filter 3</li>
                            <li>Filter 4</li>
                            <li>Filter 5</li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className={listNone}>
                            {clusters.map((cluster,i) => (
                                <>{cluster.children.map((child,ii) => (
                                    <li key={ii+"-key"} className={boxStyle}>
                                        <p><Link to={process.env.PUBLIC_URL+"/cluster/"+child.shortcode}>{child.name}</Link></p>
                                        <p>Cluster: {cluster.name}</p>
                                        <p>{child.description}</p>
                                    </li>
                                ))}</>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </WebTemplate>
    )
}

export default SearchResults