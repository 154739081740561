import React from 'react';
import ObrizumTemplate from '../../components/ObrizumTemplate';

const Placeholder = function (props) {
    return (
        <ObrizumTemplate>
            <div className="d-flex justify-content-center " style={{ height: '100vh' }}>
                <img src={props.screenshot} alt="Demo Screenshot" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
        </ObrizumTemplate>
    );
};

export default Placeholder;
