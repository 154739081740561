import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Update to useNavigate
import WebTemplate from '../components/WebTemplate';
import Marketing from '../components/Marketing';
import Hero from '../components/Hero';
import { css } from '@emotion/css';
import { removeCourse } from '../redux/auth'; // Adjust the import according to your file structure

const clusterDetailStyles = css`
    .cluster-header {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 4.5em;
        font-weight: bold;
    }
    .cluster-description {
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 40px;
        padding: 0 20px;
    }
    .knowledge-header {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
        margin-left: 40px;
    }
    .knowledge-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1440px;
        margin: 0 auto;
        padding-top: 20px;
    }
    .card {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        transition: transform 0.2s;
        position: relative;
    }
    .card:hover {
        transform: translateY(-10px);
    }
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        flex: 1;
    }
    .pricing-button {
        margin-top: 16px;
        background: transparent;
        border: 2px solid #007bff;
        padding: 6px;
        font-size: 16px;
        font-weight: bold;
        color: #007bff;
        transition: background 0.3s, color 0.3s;
    }
    .card-title {
        font-size: 1em;
        margin-bottom: 15px;
    }
    .card-text {
        font-size: 1em;
        color: #666;
        margin-bottom: 15px;
        flex-grow: 1;
    }
    .courses-header {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .course-title {
        font-size: 14px;
        color: #004080;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .list-group-item {
        padding: 10px 0px;
        border-bottom: 0;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .button-bar {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        background: linear-gradient(45deg, #6a11cb, #2575fc);
        margin: 0 20px;
        border-radius: 10px;
    }
    .btn-group {
        width: 100%;
    }
    .button-bar .btn {
        margin: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 10px 20px;
        backdrop-filter: blur(10px);
        transition: background-color 0.3s, transform 0.3s;
        font-size: 14px;
        font-weight: bold;
    }
    .button-bar .btn:hover {
        background-color: rgba(255, 255, 255, 0.4);
        transform: scale(1.05);
    }
    .empty-card {
        display: none;
    }
    .button-container {
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }
    .checkbox-container {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .checkbox-label {
        font-size: 14px;
        color: #004080;
    }
`;

const HomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Update to useNavigate
    const auth = useSelector((state) => state.auth);

    const handleLaunchCourse = (courseISBN) => {
        navigate(`${process.env.PUBLIC_URL}/obrizum/course/${courseISBN}`);
    };

    const handleRemoveCourse = (courseISBN) => {
        dispatch(removeCourse(courseISBN));
    };

    const insertLink = (text, word, url) => {
        const parts = text.split(new RegExp(`(${word})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === word.toLowerCase()
                ? <a key={index} href={`${process.env.PUBLIC_URL}/about`} target="_blank" rel="noopener noreferrer">{part}</a>
                : part
        );
    };

    const marketingContent = [
        {
            title: 'Personalized Learning',
            text: insertLink(
                'Pearson Advantage offers personalized learning experiences tailored to your individual needs. Our adaptive learning technology driven by AI customizes the course content based on your competence and confidence, helping you achieve mastery of the content up to 2x faster than traditional learning and delivering a unique learning journey for each user. Learn more about the benefits of adaptive learning.',
                'adaptive learning',
                'https://www.example.com/adaptive-learning' // Update with the actual URL
            ),
        },
        {
            title: 'Access to the Voices of the Experts',
            text: 'Pearson IT Professional, who provide all the content in this vast library, has long been synonymous with high-quality IT training.  The courses in this library provide you with the highest caliber IT learning content in the industry. Our content is developed by authors and trainers who are well-known experts and thought leaders in their fields and includes officially endorsed material from our partnerships with Cisco and Microsoft, so you will never need to worry about wasting your time studying from inferior content.',
        },
        {
            title: 'Highly Curated Library',
            text: 'The Pearson Advantage library of courses is highly curated, helping you quickly find the resources to attain the skills you need. Unlike other IT course libraries that force you to search through hundreds of seemingly identical course listings, Pearson Advantage courses are hand selected, providing you with only the highest-quality learning experiences. Choose from hundreds of courses that focus on the most coveted IT certifications and most in-demand IT job skills in areas such as AI/ML, Data Science, Cybersecurity, Cloud Computing, DevOps, Computer Networking, Programming, and Software Engineering, In addition, you will benefit from a series of Power Skills courses that help you develop your non-technical skills for career success.',
        },
        {
            title: 'Intelligent Search',
            text: 'The Intelligent Search feature provides you with the ability to search the entire library to find micro-learning modules and quick, practical answers to technology problems.',
        },
        {
            title: 'Continuous Updates',
            text: 'Stay ahead with Pearson Advantage’s continuous updates. Our platform evolves with the latest educational trends and technologies to provide the best learning experience. New courses are continually added to the library, helping you stay ahead of the fast pace of technology change.',
        },
        {
            title: 'Detailed Analytics',
            text: 'Custom analytics reports show progress, insights, and recommendations at every step, helping you identify gaps in knowledge and confidence and target future learning goals.',
        },

    ];

    return (
        <WebTemplate>
            {auth.loggedIn ? (
                <Container fluid>
                    <Row>
                        <Col>
                            <div className={clusterDetailStyles} style={{ maxWidth: '1440px', margin: '0 auto' }}>
                                <h1 style={{ textAlign: 'center', marginTop: '40px', fontSize: '2.5rem', fontWeight: '700' }}>My Courses</h1>

                                <div className="knowledge-list">
                                    {auth.me.courses ? auth.me.courses.map((course, i) => (
                                        <div key={i}>
                                            <Card className="flex-fill">
                                                <Card.Body>
                                                    <Card.Title>{course.Title}</Card.Title>
                                                    <Button className="pricing-button" onClick={() => handleLaunchCourse(course.ISBN)}>Open Course</Button>
                                                    <Button className="pricing-button" variant="danger" style={{color: 'red', borderColor: 'red'}} onClick={() => handleRemoveCourse(course.ISBN)}>Remove Course</Button>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container fluid>
                    <Row>
                        <Col style={{ padding: 0 }}>
                            <Hero />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            top: '-100px',
                            margin: '100px',
                        }}
                    >
                        <h2 style={{textAlign: 'center'}}>Pearson Advantage is a highly-instrumented adaptive learning platform driven by AI</h2>
                        {marketingContent.map((content, index) => (
                            <Col key={index} lg={6} className="d-flex align-items-stretch" style={{ padding: '10px', minHeight:'300px' }}>
                                <Marketing title={content.title} text={content.text} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
        </WebTemplate>
    );
};

export default HomePage;
