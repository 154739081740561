import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ObrizumHeader from '../components/ObrizumHeader';
import { css } from '@emotion/css';
import Header from "./Header";
import Footer from "./Footer";

const footerStyles = css`
    height: 200px;
    border-top: 1px solid #ccc;
    margin-top: 24px;
    padding: 32px 0;
`

const ObrizumTemplate = function(props){
    return (
        <>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>
        </>
    )
}

export default ObrizumTemplate