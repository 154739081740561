import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';

// Initial state
const initialState = {
    subtopics: [],
    loading: false,
    error: null,
};

// Async thunk for fetching subtopics
export const fetchSubtopics = createAsyncThunk(
    'subtopics/fetchSubtopics',
    async (_, { rejectWithValue }) => {
        try {
            const subtopicsRef = ref(database, 'subtopics'); // Ensure 'subtopics' is the correct path
            const snapshot = await get(subtopicsRef);
            if (snapshot.exists()) {
                const subtopics = snapshot.val();
                return Object.values(subtopics); // Convert to array if it's an object
            } else {
                throw new Error("Subtopics not found");
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Create slice
const subtopicSlice = createSlice({
    name: 'subtopics',
    initialState,
    reducers: {
        setSubtopic: (state, action) => {
            state.subtopics = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubtopics.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubtopics.fulfilled, (state, action) => {
                state.loading = false;
                state.subtopics = action.payload;
            })
            .addCase(fetchSubtopics.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions from the slice for use in components.
export const { setSubtopic } = subtopicSlice.actions;

// Selectors
export const selectSubtopics = (state) => state.subtopics.subtopics;
export const selectLoading = (state) => state.subtopics.loading;
export const selectError = (state) => state.subtopics.error;

export default subtopicSlice.reducer;
