import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, ListGroup, Breadcrumb } from 'react-bootstrap';
import { css } from '@emotion/css';
import { FaBook, FaPlay } from 'react-icons/fa';
import WebTemplate from '../components/WebTemplate';

const subTopicDetailStyles = css`
    .subtopic-header {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 2.5em;
        font-weight: bold;
    }
    .subtopic-description {
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 40px;
        padding: 0 20px;
    }
    .knowledge-header {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
        margin-left: 40px;
    }
    .knowledge-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1440px;
        margin: 0 auto;
        padding-top: 20px;
    }
    .card {
        margin-bottom: 20px;
        width: 100%;

        .card-body {
            padding: 40px;
        }

        .card-title {
            font-size: 1.5em;
            margin-bottom: 15px;
        }

        .card-text {
            font-size: 1em;
            color: #666;
        }
    }
    .courses-header {
        margin-top: 20px;
        font-size: 1.2em;
        font-weight: bold;
    }
    .course-title {
        font-size: 1em;
        color: #004080;
    }
    .list-group-item {
        padding: 10px 15px;
        border-bottom: 1px solid #ccc;
    }
`;

const breadcrumbStyles = css`
    display: flex;
    justify-content: center;
    margin: 20px 0;
    font-size: 1rem;

    .breadcrumb-item + .breadcrumb-item::before {
        content: '>';
        color: #333;
        padding: 0 5px;
    }

    .breadcrumb-item a {
        color: #0056b3; /* Use your primary color */
        text-decoration: none;
    }

    .breadcrumb-item a:hover {
        text-decoration: underline;
    }

    .breadcrumb-item.active {
        color: #666; /* Use a muted color for the active item */
    }
`;

const SubTopicDetail = function() {
    const { id } = useParams();
    const subtopics = useSelector(state => state.subtopics.subtopics);
    const courses = useSelector(state => state.courses.courses);
    const knowledge = useSelector(state => state.knowledge.knowledge);

    const getSubTopic = (sid) => {
        let toReturn;
        if (subtopics && subtopics.length > 0) {
            subtopics.forEach(s => {
                if (s.id.toString() === sid) toReturn = s;
            });
            return toReturn;
        }
        return toReturn;
    };

    const subtopic = getSubTopic(id);

    // Find all the knowledge where subtopic.id === knowledge.subtopicId
    const relatedKnowledge = knowledge.filter(k => k.subTopicId?.toString() === subtopic.id?.toString());

    console.log('103', knowledge)
    // Filter out the related knowledge with associated courses
    const filteredKnowledge = relatedKnowledge.filter(item => courses.some(course => course['Knowledge Space'] === item.name));

    return (
        <WebTemplate>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb className={breadcrumbStyles}>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/catalog" }}>Catalog</Breadcrumb.Item>
                            <Breadcrumb.Item active>{subtopic?.name ?? "No subtopic name"}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className={subTopicDetailStyles}  style={{maxWidth:'1440px', margin: '0 auto'}}>
                            <h1 className="subtopic-header">{subtopic?.name ?? "No subtopic name"}</h1>
                            <p className="subtopic-description">{subtopic?.description ?? "No description"}</p>

                            <div className="knowledge-list">
                                <Row style={{ width: '100%' }}>
                                    {filteredKnowledge.map((item) => {
                                        const itemCourses = courses.filter(course => course['Knowledge Space'] === item.name);
                                        return (
                                            <Col key={item.id} xs={12} md={6} lg={12}>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>{item.name}</Card.Title>
                                                        <Card.Text>{item.description}</Card.Text>
                                                        <div className="courses-header">Courses:</div>
                                                        <ListGroup variant="flush">
                                                            {itemCourses.map((course) => (
                                                                <ListGroup.Item key={course.id} className="course-title">
                                                                    {course.Format.toLowerCase() === 'book' && <FaBook />}
                                                                    {course.Format.toLowerCase() === 'video' && <FaPlay />}
                                                                    <span style={{ marginLeft: '15px' }}>{course.Title}</span>
                                                                </ListGroup.Item>
                                                            ))}
                                                        </ListGroup>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </WebTemplate>
    );
};

export default SubTopicDetail;
