import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import { login } from '../redux/auth';
import { css } from '@emotion/css';
import bg from '../assets/images/bg.jpg';

const formStyles = css`
    border: none;
    padding: 32px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    width: 100%;
    max-width: 400px;
    margin: auto;
    animation: fadeIn 1s ease-in-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    h1 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;
    }

    .form-group {
        margin-bottom: 16px;
    }

    .form-label {
        font-weight: bold;
        color: #333;
        display: block;
        margin-bottom: 8px;
    }

    .pricing-button {
        margin-top: 16px;
        background: transparent;
        border: 2px solid #007bff;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #007bff;
        transition: background 0.3s, color 0.3s;
    }

    .pricing-button:hover {
        background: #007bff;
        color: #fff;
    }

    .form-control {
        border-radius: 5px;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.2s;
        width: 100%;
    }

    .form-control:focus {
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    }
`;

const containerStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    padding: 20px;
`;

const Login = function() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);

    function cancel() {
        navigate('/')
    }

    const loginAction = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('email');
        const password = formData.get('password');
        dispatch(login(email, password));
    };

    useEffect(() => {
        if (auth.loggedIn) {
            navigate(process.env.PUBLIC_URL + '/');
        }
        if (auth.error) {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    }, [auth, navigate]);

    return (
        <div className={containerStyles}>
            {!auth.loggedIn ? (
                <Form onSubmit={loginAction} className={formStyles}>
                    <h1>Log in to Pearson Advantage</h1>
                    <Form.Group controlId='email' className="form-group">
                        <Form.Label className="form-label">Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter your email here"></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='password' className="form-group">
                        <Form.Label className="form-label">Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Enter your password"></Form.Control>
                    </Form.Group>
                    <Button type="submit" className="pricing-button">Log in</Button>
                    <Button onClick={cancel} type="button" className="pricing-button" style={{marginLeft:'12px'}}>Cancel</Button>

                </Form>
            ) : 'Already logged in. Please click on home.'}
            <ToastContainer position="top-start" className="p-3" style={{width: '100%'}}>
                <Toast show={showToast} onClose={() => setShowToast(false)} bg="danger" style={{width: '45%'}}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body style={{color: 'white'}}>{auth.error}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default Login;
