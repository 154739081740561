import React, { useState } from 'react';
import { Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import { css } from '@emotion/css';
import bg from '../assets/images/bg.jpg';
import { auth, database } from '../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { useDispatch } from 'react-redux';
import { login } from '../redux/auth';
import { useNavigate } from 'react-router-dom';

const formStyles = css`
    border: none;
    padding: 32px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    width: 100%;
    max-width: 400px;
    margin: auto;
    animation: fadeIn 1s ease-in-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    h1 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;
    }

    .form-group {
        margin-bottom: 16px;
    }

    .form-label {
        font-weight: bold;
        color: #333;
        display: block;
        margin-bottom: 8px;
    }

    .form-control {
        border-radius: 5px;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.2s;
        width: 100%;
    }

    .form-control:focus {
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    }

    .pricing-button {
        margin-top: 16px;
        background: transparent;
        border: 2px solid #007bff;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #007bff;
        transition: background 0.3s, color 0.3s;
    }

    .pricing-button:hover {
        background: #007bff;
        color: #fff;
    }
`;

const containerStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    padding: 20px;
`;

const Subscription = function() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const cancel = () => {
        navigate('/');
    };

    const signUpAction = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const userData = {
            name: formData.get('name'),
            email: formData.get('email'),
            password: formData.get('password'),
            passwordConfirmation: formData.get('passwordConfirmation')
        };

        if (userData.password !== userData.passwordConfirmation) {
            setToastMessage("Passwords do not match");
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
            const user = userCredential.user;
            console.log("User signed up: ", user);

            // Write user data to Firebase Realtime Database
            await set(ref(database, 'users/' + user.uid), {
                username: userData.name,
                email: userData.email
            });

            // Automatically log in the user after successful sign-up
            dispatch(login(userData.email, userData.password));
            navigate(process.env.PUBLIC_URL + '/');
        } catch (error) {
            setToastMessage(error.message);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    };

    return (
        <div className={containerStyles}>
            <Form onSubmit={signUpAction} className={formStyles}>
                <h1>Sign Up for Pearson Advantage</h1>
                <Form.Group controlId='name' className="form-group">
                    <Form.Label className="form-label">Name</Form.Label>
                    <Form.Control type="text" name="name" placeholder="Enter your name"></Form.Control>
                </Form.Group>
                <Form.Group controlId='email' className="form-group">
                    <Form.Label className="form-label">Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Enter your email"></Form.Control>
                </Form.Group>
                <Form.Group controlId='password' className="form-group">
                    <Form.Label className="form-label">Password</Form.Label>
                    <Form.Control type="password" name="password" placeholder="Enter your password"></Form.Control>
                </Form.Group>
                <Form.Group controlId='passwordConfirmation' className="form-group">
                    <Form.Label className="form-label">Confirm Password</Form.Label>
                    <Form.Control type="password" name="passwordConfirmation" placeholder="Confirm your password"></Form.Control>
                </Form.Group>
                <Button type="submit" className="pricing-button">Sign Up</Button>
                <Button onClick={cancel} type="button" className="pricing-button" style={{ marginLeft: '12px' }}>Cancel</Button>
            </Form>
            <ToastContainer position="top-start" className="p-3" style={{ width: '100%' }}>
                <Toast show={showToast} onClose={() => setShowToast(false)} bg="danger" style={{ width: '45%' }}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body style={{ color: 'white' }}>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default Subscription;
