import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import WebTemplate from '../components/WebTemplate';
import { database } from '../firebase';
import { ref, get, update } from 'firebase/database';
import { updateSavedCourses } from '../redux/auth';
import KnowledgeCard from '../components/KnowledgeCard';
import '../assets/css/ClusterDetail.css';

const ClusterDetail = function() {
    const { id, subtopicId } = useParams();
    const clusters = useSelector(state => state.clusters.clusters);
    const subtopics = useSelector(state => state.subtopics.subtopics);
    const knowledge = useSelector(state => state.knowledge.knowledge);
    const courses = useSelector(state => state.courses.courses);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [cluster, setCluster] = useState(null);
    const [relatedKnowledge, setRelatedKnowledge] = useState([]);
    const [selectedSubtopic, setSelectedSubtopic] = useState(subtopicId || null);
    const [savedCourses, setSavedCourses] = useState([]);
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);

    const fetchCluster = useCallback(() => {
        const foundCluster = clusters.find(c => c.id.toString() === id.toString());
        setCluster(foundCluster);
    }, [clusters, id]);

    const fetchRelatedKnowledge = useCallback(() => {
        if (cluster) {
            const newFilteredKnowledge = knowledge.filter(item => item.catId === cluster.id);
            const filteredKnowledge = newFilteredKnowledge.filter(item => {
                const relatedCourses = courses.filter(course => course['Knowledge Space'] === item.name);
                return relatedCourses.length > 0;
            });
            setRelatedKnowledge(filteredKnowledge);
        }
    }, [cluster, knowledge, courses]);

    useEffect(() => {
        fetchCluster();
    }, [fetchCluster]);

    useEffect(() => {
        fetchRelatedKnowledge();
    }, [fetchRelatedKnowledge, cluster]);

    useEffect(() => {
        if (auth.me) {
            const userRef = ref(database, `users/${auth.me.id}`);
            get(userRef).then(snapshot => {
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    setSavedCourses(userData.courses || []);
                }
            });
        }
    }, [auth.me]);

    useEffect(() => {
        setSelectedSubtopic(subtopicId || null);
    }, [subtopicId]);

    const handleFilterClick = useCallback((subtopicId) => {
        setSelectedSubtopic(subtopicId);
        navigate(`/cluster/${id}/${subtopicId || ''}/`, { replace: true });
    }, [navigate, id]);

    const tryNow = useCallback(() => {
        navigate(process.env.PUBLIC_URL + "/subscription");
    }, [navigate]);

    const handleToggle = useCallback(async (course) => {
        if (!auth.me) {
            console.error('User is not authenticated');
            return;
        }

        const userRef = ref(database, `users/${auth.me.id}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
            const userData = snapshot.val();
            let currentCourses = userData.courses || [];

            let updatedCourses;
            if (currentCourses.some(c => c.ISBN === course.ISBN)) {
                updatedCourses = currentCourses.filter(c => c.ISBN !== course.ISBN);
            } else {
                updatedCourses = [...currentCourses, course];
            }

            await update(userRef, { courses: updatedCourses });
            setSavedCourses(updatedCourses);
            dispatch(updateSavedCourses(updatedCourses));
        } else {
            console.error('User data not found in the database');
        }
    }, [auth.me, dispatch]);

    const handleLaunchCourse = useCallback((course) => {
        navigate(`${process.env.PUBLIC_URL}/obrizum/course/${course.ISBN}`);
    }, [navigate]);

    const filteredKnowledge = useMemo(() => {
        const filtered = selectedSubtopic
            ? relatedKnowledge.filter(item => item.subTopicId && item.subTopicId.toString() === selectedSubtopic.toString())
            : relatedKnowledge;
        return filtered;
    }, [selectedSubtopic, relatedKnowledge]);

    const relatedSubtopics = useMemo(() => subtopics.filter(subtopic => subtopic.catId === cluster?.id), [subtopics, cluster]);

    const renderCards = useCallback(() => {
        return filteredKnowledge.map((item, index) => {
            const itemCourses = courses.filter(course => course['Knowledge Space'] === item.name);
            return (
                <KnowledgeCard
                    key={index}
                    item={item}
                    itemCourses={itemCourses}
                    auth={auth}
                    savedCourses={savedCourses}
                    handleToggle={handleToggle}
                    handleLaunchCourse={handleLaunchCourse}
                    tryNow={tryNow}
                />
            );
        });
    }, [filteredKnowledge, courses, auth, savedCourses, handleToggle, handleLaunchCourse, tryNow]);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -300,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 300,
                behavior: 'smooth'
            });
        }
    };

    if (!cluster || relatedKnowledge.length === 0) {
        return (
            <WebTemplate>
                <Container fluid>
                    <Row>
                        <Col>
                            <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
                                <h1 className="cluster-header">Loading...</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </WebTemplate>
        );
    }

    return (
        <WebTemplate>
            <Container fluid>
                <Row>
                    <Col>
                        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
                            <h1 className="cluster-header">{cluster?.category ?? "No cluster name"}</h1>
                            <p className="cluster-description">{cluster?.description ?? "No description"}</p>

                            <p className="knowledge-header">Filter {cluster?.category} knowledge by subtopic:</p>
                            <div className="button-bar-container">
                                <button className="scroll-button scroll-button-left" onClick={scrollLeft}>
                                    &lt;
                                </button>
                                <div className="button-bar" ref={scrollContainerRef}>
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => handleFilterClick(null)}
                                            className={!selectedSubtopic ? 'btn-active' : ''}
                                        >
                                            {'All'}
                                        </Button>
                                        {relatedSubtopics.map((subtopic) => (
                                            <Button
                                                key={subtopic.id}
                                                onClick={() => handleFilterClick(subtopic.id)}
                                                className={selectedSubtopic === subtopic.id.toString() ? 'btn-active' : ''}
                                            >
                                                {subtopic.name}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                                <button className="scroll-button scroll-button-right" onClick={scrollRight}>
                                    &gt;
                                </button>
                            </div>
                            <div className="knowledge-list">
                                {renderCards()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </WebTemplate>
    );
};

export default ClusterDetail;
