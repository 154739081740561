import { useEffect, useState, useRef } from "react";
import { Badge, Button, Col, Container, ProgressBar, Row, Form, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";
import Confetti from 'react-confetti';
import poster from '../assets/images/poster.jpeg';

const ObrizumCourse = function (props) {
    const content = useSelector((state) => state.content);
    const [activePage, setActivePage] = useState(0);
    const page = content[activePage];
    const [confidence, setConfidence] = useState(60);
    const [answered, setAnswered] = useState(false);
    const [answerSelected, setAnswerSelected] = useState(null);
    const [isWrong, setIsWrong] = useState(false);
    const [message, setMessage] = useState("");
    const [confettiVisible, setConfettiVisible] = useState(false);
    const alertRef = useRef(null);

    useEffect(() => {
        let confettiTimeout;
        if (confettiVisible) {
            confettiTimeout = setTimeout(() => {
                setConfettiVisible(false);
            }, 5000); // Stop confetti after 5 seconds
        }
        return () => clearTimeout(confettiTimeout);
    }, [confettiVisible]);

    const getAlertPosition = () => {
        if (alertRef.current) {
            const rect = alertRef.current.getBoundingClientRect();
            return { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
        }
        return { x: 0, y: 0 };
    };

    const rangeChange = (e) => {
        let val = e.target.value;
        setConfidence(val);
    };

    const continueClick = (e) => {
        setConfidence(60);
        setAnswered(false);
        setAnswerSelected(null);
        setIsWrong(false);
        setMessage("");
        setConfettiVisible(false);
        if (answered && activePage === 2) {
            setActivePage(1); // Redirect back to the video screen
        } else if (activePage !== 2) {
            setActivePage(activePage + 1);
        } else {
            setActivePage(0);
        }
    };

    const radioSelect = (e) => {
        setAnswerSelected(parseInt(e.target.value));
    };

    const checkAnswer = (e) => {
        if (answerSelected === parseInt(page.content.answerKey)) {
            setAnswered(true);
            setIsWrong(false);
            setMessage("Correct! Great job.");
            setConfettiVisible(true);
        } else {
            setAnswered(true);
            setIsWrong(true);
            setMessage("Sorry, that is incorrect. Please try again.");
        }
    };

    const rangeStyles = css`
        display: grid;
        grid-template-columns: 1fr 50px;
        grid-gap: 10px;
    `;

    const buttonStyles = css`
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-gap: 12px;
        margin-top: 24px;
    `;

    const progressStyles = css`
        background: #f8f9fa;
        padding: 16px;
        margin: 20px 0;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    `;

    const rateStyles = css`
        border-top: 1px solid #dee2e6;
        margin-top: 20px;
        padding-top: 18px;
    `;
    const rightStyles = css`
        background: #f8f9fa; /* Very light grey */
        border: 1px solid #dee2e6; /* Light grey border */
        padding: 16px;
        border-radius: 8px;
        transition: background 0.3s ease;
    `;
    const wrongStyles = css`
        background: rgba(244, 67, 54, 0.1);
        padding: 16px;
        border-radius: 8px;
        transition: background 0.3s ease;
    `;

    const gradientProgress = css`
        .progress-bar {
            background: linear-gradient(45deg, #6a11cb, #2575fc);
            transition: width 0.4s ease;
        }
    `;

    const formStyles = css`
        .form-check {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }
        .form-check-input:checked {
            background-color: #6a11cb;
            border-color: #6a11cb;
        }
        .form-check-label {
            padding-left: 10px;
            cursor: pointer;
            font-size: 16px;
        }
    `;
    console.log('137', props.data)
    return (
        <Container style={{ paddingBottom: '100px' }}>
            <Row>
                <Col>
                    <Container fluid className={progressStyles}>
                        <h2 style={{ marginBottom: '40px' }}>{props.data.Title}</h2>
                        <Row>
                            <Col>
                                <div>
                                    Progress to certification <strong>45%</strong>
                                </div>
                                <div>
                                    <ProgressBar now={45} className={gradientProgress} />
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    Content Covered <strong>35%</strong>
                                </div>
                                <div>
                                    <ProgressBar now={35} className={gradientProgress} />
                                </div>
                            </Col>
                            {page.type === "video" || page.type === "question" ? (
                                <Col>
                                    <div>This content is about:</div>
                                    <div>
                                        <Badge bg="secondary">{props.data.Cluster}</Badge>
                                    </div>
                                </Col>
                            ) : null}
                        </Row>
                        {activePage === 0 && (
                            <ul style={{ marginTop: '40px' }}>
                                <li><strong>Author:</strong> {props.data.Author}</li>
                                <li><strong>Cluster:</strong> {props.data.Cluster}</li>
                                <li><strong>Editor:</strong> {props.data.Editor}</li>
                                <li><strong>Format:</strong> {props.data.Format}</li>
                                <li><strong>ISBN:</strong> {props.data.ISBN}</li>
                                <li><strong>Knowledge Space:</strong> {props.data["Knowledge Space"]}</li>
                                <li><strong>Level:</strong> {props.data["Level (Beg, Intermed, Adv)"]}</li>
                                <li><strong>Pub Date:</strong> {props.data["Pub Date"]}</li>
                                <li><strong>Description:</strong>
                                    <div dangerouslySetInnerHTML={{ __html: props.data.Description }} />
                                </li>
                            </ul>
                        )}
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col>
                    {page.type === "read" ? (
                        <div className="contentContainer"></div>
                    ) : page.type === "video" ? (
                        <div>
                            <video
                                src={page.url}
                                controls
                                poster={poster} // Update with the actual path to the image
                                className={css`
                                    width: 100%;
                                    height: auto;
                                `}
                            ></video>
                        </div>
                    ) : (
                        <div>
                            {confettiVisible && (
                                <Confetti
                                    recycle={false}
                                    numberOfPieces={500}
                                    gravity={0.5} // Adjust gravity to control fall speed
                                    {...getAlertPosition()}
                                />
                            )}
                            {answered && (
                                <Alert
                                    variant={isWrong ? "danger" : "success"}
                                    ref={alertRef}
                                    className={isWrong ? "" : rightStyles}
                                >
                                    {message}
                                </Alert>
                            )}
                            <Form
                                className={`${answered && isWrong ? wrongStyles : answered && !isWrong ? rightStyles : ""} ${formStyles}`}
                            >
                                <div>{page.content.question}</div>
                                <Form.Group controlId="form1.radios">
                                    {page.content.options.map((option, i) => (
                                        <Form.Check
                                            key={i}
                                            id={`option-${i}`}
                                            label={option}
                                            value={i}
                                            name="options"
                                            type="radio"
                                            checked={answerSelected === i}
                                            onChange={(e) => setAnswerSelected(parseInt(e.target.value))}
                                            className="form-check"
                                        />
                                    ))}
                                </Form.Group>
                            </Form>
                        </div>
                    )}
                </Col>
            </Row>
            {page.type === "video" || page.type === "question" ? (
                <Row className={rateStyles}>
                    <Col>
                        <div>Rate your understanding of this content</div>
                        <div className={rangeStyles}>
                            <input type="range" value={confidence} onChange={rangeChange} />
                            <input type="text" value={confidence} readOnly />
                        </div>
                    </Col>
                </Row>
            ) : null}
            <Row>
                <Col>
                    {page.type === "video" ? (
                        <div className={buttonStyles}>
                            <div>
                                <Button className="pricing-button" variant="secondary">Your Learning Journey</Button>
                            </div>
                            <Button className="pricing-button" onClick={continueClick}>Continue</Button>
                        </div>
                    ) : page.type === "question" ? (
                        <div className={buttonStyles}>
                            <div></div>
                            <div></div>
                            {!answerSelected ? (
                                <Button disabled>Check</Button>
                            ) : answered ? (
                                <Button onClick={continueClick}>Continue</Button>
                            ) : (
                                <Button onClick={checkAnswer}>Check</Button>
                            )}
                        </div>
                    ) : (
                        <div className={buttonStyles}>
                            <div>
                                <Button className="pricing-button" variant="secondary">Your Learning Journey</Button>
                            </div>
                            <Button className="pricing-button" onClick={continueClick}>Launch</Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ObrizumCourse;
