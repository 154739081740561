import React from 'react';
import { Row, Col, ListGroup, Form } from 'react-bootstrap';
import { css } from '@emotion/css';
import { FaCheck } from 'react-icons/fa';
import ObrizumTemplate from '../../components/ObrizumTemplate';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth";
import { useNavigate } from "react-router-dom";

const sidebarStyle = css`
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 1.5rem;
`;

const profileStyle = css`
    text-align: center;
    margin-bottom: 1.5rem;

    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(135deg, #007bff, #00c6ff);
        color: white;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 auto; /* Center the avatar */
    }

    h5 {
        margin-top: 0.5rem;
        font-size: 1.25rem;
    }
`;

const listGroupItemStyle = css`
    border: none;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: flex-start;
    transition: background-color 0.2s;
    flex-direction: row !important;
    justify-content: flex-start;
    padding-left: 25px !important;

    &:hover {
        background-color: #e9ecef;
    }

    &.active {
        background-color: #007bff;
        color: white;
    }

    &.text-danger {
        color: #dc3545 !important;
    }
`;

const checkmarkStyle = css`
    margin-right: 10px;
    color: green;
    flex-shrink: 0;
`;

const mainContentStyle = css`
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const LearningJourney = function () {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signOut = async () => {
        await dispatch(logout());
        navigate(process.env.PUBLIC_URL + "/");
    };

    const getInitials = (name) => {
        if (!name) return '';
        const words = name.split(' ');
        if (words.length === 1) {
            return words[0].slice(0, 2).toUpperCase();
        }
        return (words[0][0] + words[1][0]).toUpperCase();
    };

    return (
        <ObrizumTemplate>
            <Row className="my-4">
                <Col xs={12} md={3}>
                    <div className={sidebarStyle}>
                        <div className={profileStyle}>
                            <div className="avatar">{getInitials(auth.me.username)}</div>
                            <h5>{auth.me.username}</h5>
                        </div>
                        <ListGroup variant="flush">
                            <ListGroup.Item className={listGroupItemStyle}>Personal Info</ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>Signin & Security</ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>Content Map</ListGroup.Item>
                            <ListGroup.Item className={`${listGroupItemStyle} active`}>Learning History</ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <Form.Select aria-label="Knowledge Spaces">
                                    {auth.me.courses ? auth.me.courses.map((course, i) => (
                                        <option key={i}>{course.Title}</option>
                                    )) : null}
                                </Form.Select>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>Options</ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>Certificates</ListGroup.Item>
                            <ListGroup.Item onClick={signOut} className={`${listGroupItemStyle} text-danger`}>Log Out</ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
                <Col xs={12} md={9}>
                    <div className={mainContentStyle}>
                        <h4 className="mb-4">Your last visited sections</h4>
                        <ListGroup variant="flush">
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Establish Information and Asset Handling Requirements</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Have you tried Intelligent Search? Looking for something specific? Type into the search...</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Data in Transit</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Data Destruction in Cloud Environments</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Network and systems security is what type of asset?</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Asset management is an organization process that should be carried out to conform...</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Information and Asset Ownership</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Reflective Exercise: Information Asset Inventory OverviewAn organization’s assets...</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>Information Asset Inventory</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>How Confident are your answers? Use the slider to indicate how confident you are...</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>In IT asset management, what is one of the most useful first steps?</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>How well do you understand the content? Under every page of content, you can rate...</span>
                            </ListGroup.Item>
                            <ListGroup.Item className={listGroupItemStyle}>
                                <FaCheck className={checkmarkStyle} />
                                <span>The IT Asset Management Lifecycle</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </ObrizumTemplate>
    );
};

export default LearningJourney;
