import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../components/Header';
import Footer from '../components/Footer'; // Adjust the path as necessary
import { css } from '@emotion/css';

const WebTemplate = (props) => {
    return (
        <>
            <Row>
                <Col style={{padding: 0}}>
                    <Header />
                </Col>
            </Row>
            <Row style={{paddingBottom: '60px'}}>
                <Col style={{padding: 0}}>
                    {props.children}
                </Col>
            </Row>
            <Row>
                <Col style={{padding: 0}}>
                    <Footer />
                </Col>
            </Row>
        </>
    );
};

export default WebTemplate;
