import React from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import ToggleButton from '../components/ToggleButton';

const KnowledgeCard = ({ item, itemCourses, auth, savedCourses, handleToggle, handleLaunchCourse, tryNow }) => {
    return (
        <div>
            <Card className="flex-fill">
                <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <div>
                        <ListGroup variant="flush">
                            {itemCourses.length > 1 ? (
                                <ListGroup.Item className="course-title">
                                    Course
                                    <div>Level: {itemCourses[0]['Level (Beg, Intermed, Adv)']}</div>
                                    {
                                        auth.loggedIn ? <div className="button-container">
                                            <ToggleButton
                                                isToggled={savedCourses.some(sc => sc.ISBN === itemCourses[0].ISBN)}
                                                onToggle={() => handleToggle(itemCourses[0])}
                                                className={savedCourses.some(sc => sc.ISBN === itemCourses[0].ISBN) ? 'remove-button' : 'pricing-button'}
                                                buttonText={savedCourses.some(sc => sc.ISBN === itemCourses[0].ISBN) ? 'Remove' : 'Save'}
                                            />
                                            <Button className={'pricing-button'}
                                                    onClick={() => handleLaunchCourse(itemCourses[0])}>Launch
                                                Course</Button>
                                        </div> : <Button className={'pricing-button'}
                                                         onClick={() => tryNow()}>Try Course
                                        </Button>
                                    }
                                </ListGroup.Item>
                            ) : (
                                itemCourses.map((course) => (
                                    <ListGroup.Item key={course.ISBN} className="course-title">
                                        <div>Duration: {course.Format === 'Book' ? '200 pages' : '45 minutes'}</div>
                                        <div>Format: {course.Format}</div>
                                        <div>Level: {course['Level (Beg, Intermed, Adv)']}</div>
                                        {
                                            auth.loggedIn ? <div className="button-container">
                                                <ToggleButton
                                                    isToggled={savedCourses.some(sc => sc.ISBN === course.ISBN)}
                                                    onToggle={() => handleToggle(course)}
                                                    className={savedCourses.some(sc => sc.ISBN === course.ISBN) ? 'remove-button' : 'pricing-button'}
                                                    buttonText={savedCourses.some(sc => sc.ISBN === course.ISBN) ? 'Remove' : 'Save'}
                                                />
                                                <Button className={'pricing-button'}
                                                        onClick={() => handleLaunchCourse(course)}>Open
                                                    Course</Button>
                                            </div> : <Button className={'pricing-button'}
                                                             onClick={() => tryNow()}>Try Course
                                            </Button>
                                        }
                                    </ListGroup.Item>
                                ))
                            )}
                        </ListGroup>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default KnowledgeCard;
