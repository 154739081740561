import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WebTemplate from '../components/WebTemplate';

const SavedCourses = function(){
    return (
        <WebTemplate>
            <h1>Saved Courses Page</h1>
            <p>Smart Space page</p>
        </WebTemplate>
    )
}

export default SavedCourses