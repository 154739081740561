import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { auth, database } from './firebase'; // Ensure the correct import path
import { onAuthStateChanged } from 'firebase/auth';
import { ref, get } from 'firebase/database';
import { loginSuccess, logout } from './redux/auth';
import { fetchClusters } from './redux/clusters'; // Ensure the correct import path
import searchImage from './assets/images/obrism/search.png';
import analyticsImage from './assets/images/obrism/analytics.png';
import learningImage from './assets/images/obrism/learning.png';
import HomePage from "./views/HomePage";
import Container from 'react-bootstrap/Container';
import AboutAdaptive from "./views/AboutAdaptive";
import Catalog from "./views/Catalog";
import ClusterDetail from "./views/ClusterDetail";
import Login from "./views/Login";
import Pricing from "./views/Pricing";
import ProductDetail from "./views/ProductDetail";
import Profile from "./views/Profile";
import SavedCourses from "./views/SavedCourses";
import SearchResults from "./views/SearchResults";
import Subscription from "./views/Subscription";
import Course from "./views/obrizum/Course";
import LearningJourney from "./views/obrizum/LearningJourney";
import Analytics from "./views/obrizum/Analytics";
import Home from "./views/obrizum/Home";
import Features from "./views/Features";
import SubTopicDetail from "./views/SubTopicDetail";
import { fetchSubtopics } from "./redux/subTopics";
import { fetchKnowledge } from "./redux/knowledge";
import { fetchCourses } from "./redux/courses";
import Placeholder from "./views/obrizum/Placeholder";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch clusters when the app launches
    dispatch(fetchClusters());
    dispatch(fetchSubtopics());
    dispatch(fetchKnowledge());
    dispatch(fetchCourses());

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User ID:', user.uid);
        // Fetch user data from Firebase Realtime Database
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          console.log('User Data:', userData);
          // User is signed in
          dispatch(loginSuccess({
            id: user.uid,
            username: userData.username,
            email: userData.email,
            courses: userData.courses || []
          }));
        } else {
          console.log('No user data available');
        }
      } else {
        // User is signed out
        dispatch(logout());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
      <Container fluid style={{ padding: 0, overflowX: 'hidden' }}>
        <Routes>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/about"} element={<AboutAdaptive />} />
          <Route path={"/catalog"} element={<Catalog />} />
          <Route path={"/cluster/:id"} element={<ClusterDetail />} />
          <Route path={"/cluster/:id/:subtopicId"} element={<ClusterDetail />} />
          <Route path={"/subtopic/:id"} element={<SubTopicDetail />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/pricing"} element={<Pricing />} />
          <Route path={"/product/:id"} element={<ProductDetail />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/saved"} element={<SavedCourses />} />
          <Route path={"/search"} element={<SearchResults />} />
          <Route path={"/subscription"} element={<Subscription />} />
          <Route path={"/features"} element={<Features />} />
          <Route path={"/obrizum"} element={<Home />} />
          <Route path={"/obrizum/course/:id"} element={<Course />} />
          <Route path={"/obrizum/journey"} element={<Placeholder screenshot={learningImage}/>} />
          <Route path={"/obrizum/analytics"} element={<Placeholder screenshot={analyticsImage}/>} />
          <Route path={"/obrizum/search"} element={<Placeholder screenshot={searchImage}/>} />
        </Routes>
      </Container>
  );
}

export default App;
