// ToggleButton.js
import React from 'react';
import { Button } from 'react-bootstrap';

const ToggleButton = ({ isToggled, onToggle, className }) => {
    return (
        <Button className={className} variant={isToggled ? "success" : "secondary"} onClick={onToggle}>
            {isToggled ? "Remove" : "Save"}
        </Button>
    );
};

export default ToggleButton;
