import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../redux/auth";
import { css } from "@emotion/css";
import { useEffect, useRef, useState, useCallback } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Search from './Search'; // Import the Search component

const Header = function (props) {
    const auth = useSelector((state) => state.auth);
    const categories = useSelector((state) => state.categories); // Assuming categories are stored in state.categories
    const clusters = useSelector(state => state.clusters.clusters); // Assuming clusters are stored in state.clusters.clusters
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const navRef = useRef(null);
    const borderRef = useRef(null);
    const [borderStyle, setBorderStyle] = useState({});

    const signIn = () => {
        navigate(process.env.PUBLIC_URL + "/login");
    };
    const signOut = async () => {
        await dispatch(logout());
        navigate(process.env.PUBLIC_URL + "/");
    };
    const tryNow = () => {
        navigate(process.env.PUBLIC_URL + "/subscription");
    };

    const updateBorderPosition = useCallback(() => {
        const activeLink = navRef.current.querySelector('.nav-link.active:not(.catalog-link):not(.cluster-link)');
        if (activeLink && borderRef.current) {
            const { offsetLeft, offsetWidth } = activeLink;
            setBorderStyle({
                left: `${offsetLeft}px`,
                width: `${offsetWidth}px`,
                transition: 'left 0.3s ease, width 0.3s ease'
            });
        } else {
            setBorderStyle({ display: 'none' });
        }
    }, [location.pathname]);

    useEffect(() => {
        updateBorderPosition();

        window.addEventListener('resize', updateBorderPosition);
        return () => {
            window.removeEventListener('resize', updateBorderPosition);
        };
    }, [updateBorderPosition]);

    const styles = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px;
        background-color: #004080;
        color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        box-sizing: border-box;

        .logo {
            display: flex;
            align-items: center;

            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            a {
                font-size: 1.5em;
                font-weight: bold;
                color: #fff;
                text-decoration: none;
            }
        }

        .nav {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: center;
            position: relative;

            .border {
                position: absolute;
                bottom: 0;
                height: 4px;
                background-color: #a0c4ff;
            }

            .nav-link {
                color: #fff;
                text-decoration: none;
                margin: 0 15px;
                font-weight: 500;
                padding: 10px 0;
                position: relative;
                transition: color 0.3s;

                &:hover {
                    color: #a0c4ff;
                }

                &.active {
                    font-weight: bold;
                }
            }
        }

        .auth-buttons {
            display: flex;
            align-items: center;

            button {
                margin: 0 5px;
                padding: 5px 15px;
                border-radius: 5px;
                border: none;
                background-color: #ffffff;
                color: #004080;
                font-weight: bold;
                cursor: pointer;
                transition: background-color 0.3s, transform 0.3s;

                &:hover {
                    background-color: #e0e0e0;
                    transform: scale(1.05);
                }
            }
        }

        .greeting {
            font-size: 16px;
            margin-right: 10px;
            color: #ffffff;
        }

        .search-bar {
            margin-right: 10px;
        }
    `;

    return (
        <header className={styles}>
            <div className="logo">
                <img
                    src={process.env.PUBLIC_URL + "/p-logo-white.svg"}
                    alt="Pearson Advantage"
                />
                <Link to={process.env.PUBLIC_URL + "/"}>Pearson Advantage</Link>
            </div>
            <nav ref={navRef} className="nav">
                <span
                    className="border"
                    ref={borderRef}
                    style={borderStyle}
                ></span>
                {!auth.loggedIn && (
                    <>
                        <Link to={process.env.PUBLIC_URL + "/about"} className={location.pathname === process.env.PUBLIC_URL + '/about' ? 'active nav-link' : 'nav-link'}>
                            Adaptive Learning
                        </Link>
                        <Link to={process.env.PUBLIC_URL + "/features"} className={location.pathname === process.env.PUBLIC_URL + '/features' ? 'active nav-link' : 'nav-link'}>
                            Features
                        </Link>
                        <Link to={process.env.PUBLIC_URL + "/pricing"} className={location.pathname === process.env.PUBLIC_URL + '/pricing' ? 'active nav-link' : 'nav-link'}>
                            Pricing
                        </Link>
                    </>
                )}
                {auth.loggedIn && (
                    <Link to={process.env.PUBLIC_URL + "/"} className={location.pathname === process.env.PUBLIC_URL + '/' ? 'active nav-link' : 'nav-link'}>
                        My Courses
                    </Link>
                    )}
                <NavDropdown title="Catalog" id="catalog-dropdown" className="catalog-link">
                    {categories?.map((category) => (
                        <NavDropdown.Item key={category.id} as={Link} to={`${process.env.PUBLIC_URL}/catalog/${category.id}`}>
                            {category.name}
                        </NavDropdown.Item>
                    ))}
                    <NavDropdown.Divider />
                    {clusters?.map((cluster) => (
                        <NavDropdown.Item key={cluster.id} className="cluster-link" as={Link} to={`${process.env.PUBLIC_URL}/cluster/${cluster.id}`}>
                            {cluster.category}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
                {auth.loggedIn && (
                    <>
                        <Link to={process.env.PUBLIC_URL + "/obrizum/analytics"} className={location.pathname === process.env.PUBLIC_URL + '/obrizum/analytics' ? 'active nav-link' : 'nav-link'}>
                            Analytics
                        </Link>
                        <Link to={process.env.PUBLIC_URL + "/obrizum/journey"} className={location.pathname === process.env.PUBLIC_URL + '/obrizum/journey' ? 'active nav-link' : 'nav-link'}>
                            Learning Library
                        </Link>
                        <Link to={process.env.PUBLIC_URL + "/obrizum/search"} className={location.pathname === process.env.PUBLIC_URL + '/obrizum/search' ? 'active nav-link' : 'nav-link'}>
                            Intelligent Search
                        </Link>
                    </>
                )}
            </nav>
            <div className="auth-buttons">
                <div className="search-bar">
                    <Search /> {/* Use the Search component here */}
                </div>
                {!auth.loggedIn ? (
                    <>
                        <button onClick={signIn}>Sign in</button>
                        <button onClick={tryNow}>Try now!</button>
                    </>
                ) : (
                    <>
                        <div className="greeting">{`Hi there, ${auth.me.username}`}</div>
                        <button onClick={signOut}>Logout</button>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;
